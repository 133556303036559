import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileAgainProgram = () => {
  return (
    <Layout>
      <SEO
        title="Smile Again Program | Amarillo Oral & Maxillofacial Surgery & Dental Implants"
        description="It is time for a brand new, healthy, beautiful smile at Amarillo Oral & Maxillofacial Surgery & Dental Implants."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-desktop-hero-image"
            alt="Smile Again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-mobile-hero-image"
            alt="Smile Again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Have a Healthy Smile Again</h1>

            <p>
              Meet our 2023 Smile Again recipient, Andrew, a resilient and
              determined individual from Amarillo, Texas. Andrew's journey
              echoes the transformative impact of the Smile Again Program,
              aiming to bring hope and confidence back into the lives of those
              who have faced oral health challenges.
            </p>

            <p>
              Andrew's oral health struggles began in his early years,
              influenced by a lack of awareness about the importance of daily
              oral care. Raised in an environment where oral hygiene wasn't a
              priority, he found himself dealing with the consequences later in
              life. Despite his efforts to address the damage, the absence of
              insurance made it nearly impossible to keep up with necessary
              treatments.
            </p>

            <p>
              Andrew's motivation to restore his smile goes beyond personal
              aesthetics; it encompasses securing a healthier future for himself
              and his family. His oral health has deeply affected his children
              and his wife. Improving his oral health will improve his overall
              health and quality of life and break the cycle of poor oral health
              that affected previous generations in his family. His dedication
              to bettering his health will give his children a positive role
              model to look up to and give his wife a healthier husband.
            </p>

            <p>
              Professionally, Andrew faced a career setback after a
              life-altering truck accident. While he initially aspired to be a
              truck driver, the incident forced him to reevaluate his goals.
              Now, with a renewed determination to overcome obstacles, he is
              ready to get back on track, pursue his CDL, and reenter the
              workforce.
            </p>

            <p>
              Andrew's journey is a testament to resilience, hope, and the
              pursuit of a brighter future. As he applies for the Smile Again
              Program for the third time, his unwavering spirit shines through.
              Andrew pledges not to take this gift for granted and is committed
              to making the most of this second chance to a handsome smile.
            </p>

            <p>
              We invite you to join us in supporting Andrew on his journey to a
              healthier, more confident life. Together, we can make a lasting
              impact on his life and the lives of those around him.
            </p>

            <h3 className="sas__subheading">THE 4-HOUR SMILE PROCEDURE</h3>

            <p>
              The{" "}
              <Link
                to="/procedure/4-hour-smile-amarillo-tx/"
                title="Learn more about the 4-Hour Smile">
                4-Hour Smile
              </Link>{" "}
              procedure gives patients who are missing most or all of their
              teeth a permanent, efficient, and cost-effective solution to
              completely restore their smile. A complete upper or lower arch of
              teeth can be replaced using as few as four dental implants per
              arch. The results are beautiful, healthy, and long lasting.
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <ImageSlider
              adaptiveHeight
              images={["AOMS/DEV/smileagain-2024-recipient-1"]}
            />
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            {/* <p>
              <i>
                Thank you for your interest in the Smile Again program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </i>
            </p>
            <br /> */}
            <p>
              Like us on{" "}
              <a
                href="https://www.facebook.com/thirdmolars/?fref=ts"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              to follow Andrew’s journey to his new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedure/dental-implants-amarillo-tx/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and the{" "}
                <Link
                  to="/procedure/4-hour-smile-amarillo-tx/"
                  title="Learn more about the 4-Hour Smile">
                  4-Hour Smile
                </Link>{" "}
                procedure to see how they can benefit your smile. Whether you
                need to replace one tooth or an entire arch of teeth, our team
                has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
